var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"index"},[_c('van-nav-bar',{scopedSlots:_vm._u([{key:"title",fn:function(){return [_c('span',{staticStyle:{"font-weight":"bold"}},[_vm._v(" "+_vm._s(_vm.getTitle)+" ")])]},proxy:true},{key:"left",fn:function(){return [_c('van-icon',{attrs:{"name":"wap-nav","color":"#323233","size":"20"},on:{"click":function($event){_vm.show = true}}})]},proxy:true}])}),_c('van-popup',{style:({ height: '100%' }),attrs:{"position":"top","closeable":"","close-icon-position":"top-left","get-container":"#child"},model:{value:(_vm.show),callback:function ($$v) {_vm.show=$$v},expression:"show"}},[_c('div',{staticClass:"my-nav"},[_c('van-cell-group',[_c('van-cell',{staticClass:"my-nav-cell",attrs:{"clickable":"","title":"首页","is-link":"","to":"/"},on:{"click":function($event){_vm.show = false}}}),_c('van-cell',{staticClass:"my-nav-cell",attrs:{"clickable":"","title":"小组介绍","is-link":"","to":"/introduction"},on:{"click":function($event){_vm.show = false}}}),_c('van-cell',{staticClass:"my-nav-cell",attrs:{"clickable":"","title":"小组成员","is-link":"","to":"/peoplelist"},on:{"click":function($event){_vm.show = false}}}),_c('van-collapse',{attrs:{"accordion":""},model:{value:(_vm.activeName),callback:function ($$v) {_vm.activeName=$$v},expression:"activeName"}},[_c('van-collapse-item',{staticClass:"my-nav-cell",attrs:{"title":"栏目","name":"1"}},_vm._l((_vm.kinds),function(item,index){return _c('van-cell',{key:index,staticClass:"my-nav-cell",attrs:{"clickable":"","title":item.columnTitle,"is-link":"","to":{
                                name: 'WordsList',
                                params: {
                                    kind: item.columnId,
                                    title: item.columnTitle,
                                },
                            }},on:{"click":function($event){_vm.show = false}}})}),1)],1),(!_vm.getLoginFlag)?_c('van-cell',{staticClass:"my-nav-cell",attrs:{"clickable":"","title":"登录","is-link":"","to":"/login"},on:{"click":function($event){_vm.show = false}}}):_vm._e(),(_vm.getLoginFlag)?_c('van-cell',{staticClass:"my-nav-cell",attrs:{"clickable":"","title":"登出","is-link":""},on:{"click":function($event){_vm.show = false;
                        _vm.logout();}}}):_vm._e()],1)],1)]),_c('div',{staticClass:"index-content",attrs:{"id":"child"}},[_c('router-view')],1),_c('div',{staticClass:"index-footer my-font-md"},[_vm._v(" Copyright © 2021 IMU NetGroup ")])],1)}
var staticRenderFns = []

export { render, staticRenderFns }