<template>
    <div class="index">
        <van-nav-bar>
            <template #title>
                <span style="font-weight: bold"> {{ getTitle }} </span>
            </template>
            <template #left>
                <van-icon
                    name="wap-nav"
                    color="#323233"
                    size="20"
                    @click="show = true"
                />
            </template>
        </van-nav-bar>
        <van-popup
            v-model="show"
            position="top"
            closeable
            close-icon-position="top-left"
            get-container="#child"
            :style="{ height: '100%' }"
        >
            <div class="my-nav">
                <van-cell-group>
                    <van-cell
                        class="my-nav-cell"
                        @click="show = false"
                        clickable
                        title="首页"
                        is-link
                        to="/"
                    />
                    <van-cell
                        class="my-nav-cell"
                        @click="show = false"
                        clickable
                        title="小组介绍"
                        is-link
                        to="/introduction"
                    />
                    <van-cell
                        class="my-nav-cell"
                        @click="show = false"
                        clickable
                        title="小组成员"
                        is-link
                        to="/peoplelist"
                    />
                    <van-collapse v-model="activeName" accordion>
                        <van-collapse-item
                            class="my-nav-cell"
                            title="栏目"
                            name="1"
                        >
                            <van-cell
                                v-for="(item, index) in kinds"
                                :key="index"
                                class="my-nav-cell"
                                @click="show = false"
                                clickable
                                :title="item.columnTitle"
                                is-link
                                :to="{
                                    name: 'WordsList',
                                    params: {
                                        kind: item.columnId,
                                        title: item.columnTitle,
                                    },
                                }"
                            />
                        </van-collapse-item>
                    </van-collapse>
                    <van-cell
                        v-if="!getLoginFlag"
                        class="my-nav-cell"
                        @click="show = false"
                        clickable
                        title="登录"
                        is-link
                        to="/login"
                    />
                    <van-cell
                        v-if="getLoginFlag"
                        class="my-nav-cell"
                        @click="
                            show = false;
                            logout();
                        "
                        clickable
                        title="登出"
                        is-link
                    />
                </van-cell-group>
            </div>
        </van-popup>
        <div id="child" class="index-content">
            <router-view></router-view>
        </div>
        <div class="index-footer my-font-md">
            Copyright &copy; 2021 IMU NetGroup
        </div>
    </div>
</template>

<script>
import { getToken, removeToken } from "@/utils/auth";
import { getColumnListAsc } from "@/api/common";
export default {
    components: {},
    data() {
        return {
            show: false,
            title: "",
            activeName: "",
            loginFlag: false,
            kinds: "",
        };
    },
    computed: {
        getTitle() {
            if (
                this.$route.meta.title !== "" &&
                this.$route.meta.title !== undefined
            ) {
                // console.log(this.$route.meta.title);
                return this.$route.meta.title;
            } else {
                return "内蒙古大学网络组";
            }
        },
        getLoginFlag() {
            if (getToken()) {
                return true;
            } else {
                return false;
            }
        },
    },
    created() {},
    mounted() {
        if (getToken()) {
            this.loginFlag = true;
        }
        this.getColumnListAsc();
    },
    methods: {
        logout() {
            removeToken();
            location.reload();
        },
        getColumnListAsc() {
            getColumnListAsc().then((res) => {
                if (res.code === 20000) {
                    if (!getToken()) {
                        // 未登录
                        let arr = [];
                        for (let i = 0; i < res.data.length; i++) {
                            if(res.data[i].columnpublicshow === 1) {
                                arr.push(res.data[i]);
                            }
                        }
                        this.kinds = arr;
                    } else {
                        this.kinds = res.data;
                    }
                }
            });
        },
    },
};
</script>

<style scoped>
.index {
    display: flex;
    flex-direction: column;

    min-height: 100vh;
}

.my-nav {
    padding-top: 3rem;
    padding-right: 1.5rem;
    padding-left: 1.5rem;
}

.my-nav-a {
    width: 100%;

    line-height: 3rem;

    border-bottom-style: solid;
    border-color: #ebedf0;
}

.my-nav-cell {
    font-weight: bold;
}

>>> .van-nav-bar__title {
    color: #121212;
}

>>> .van-collapse-item__content {
    padding-top: 0;
    padding-bottom: 0;
}

.index-footer {
    margin-top: auto;

    height: 3rem;
    line-height: 2rem;

    text-align: center;
}
</style>
